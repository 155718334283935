import request from "../http/request";

//获取验证码
export const bindSms = (data) => {
  return request({
    url: "/api/merchant/wechat/merchant-info/bind/wechat/send/sms",
    method: "post",
    data,
  });
};

//查看商户是否绑定
export const isBind = (params) => {
  return request({
    url: "/api/merchant/wechat/merchant-info/bind/wechat/check",
    method: "get",
    params,
  });
};

//绑定商户
export const bind = (data) => {
  return request({
    url: "/api/merchant/wechat/merchant-info/submit/bind/wechat",
    method: "post",
    data,
  });
};

//获取绑定信息
export const getInfo = (params) => {
  return request({
    url: "/api/merchant/wechat/merchant-info/get/untie/merchant/info",
    method: "get",
    params,
  });
};

//立即解绑
export const Unbinding = (params) => {
  return request({
    url: "/api/merchant/wechat/merchant-info/submit/untie/wechat",
    method: "get",
    params,
  });
};

//获取授权认证二维码信息
export const codeInfo = (params) => {
  return request({
    url: "/api/merchant/wechat/merchant-info/report/info",
    method: "get",
    params,
  });
};
