<template>
  <div class="box">
    <!-- <div class="header-tip">当前微信已绑定以下商户</div> -->
    <div class="img-box">
      <img src="../../assets/bind/isBind.png" width="100%" />
    </div>
    <div class="content">
      <div class="item-box">
        <div class="name">商户名称</div>
        <div class="info">{{ infoData.businessName || "-" }}</div>
      </div>
      <div class="item-box">
        <div class="name">商户编号</div>
        <div class="info">{{ infoData.dmCode || "-" }}</div>
      </div>
      <div class="item-box">
        <div class="name">机具TUSN</div>
        <div class="info">{{ infoData.snCode || "-" }}</div>
      </div>
    </div>
    <div class="submit-btn" @click="submit" v-if="infoData">
      <span>解绑商户</span>
    </div>
    <van-popup v-model="show" class="dialog">
      <div class="tip">提示</div>
      <div class="messageBox">
        <div class="message">确定解绑商户吗？</div>
        <div class="message">解绑后将无法接收微信消息通知~</div>
      </div>
      <div class="van-hairline--bottom"></div>
      <div class="btn-box">
        <div class="btn" @click="close">再想想</div>
        <div class="van-hairline--right"></div>
        <div class="btn" @click="unbind">立即解绑</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getInfo, Unbinding } from "@/api/bind";
import { Toast, Dialog } from "vant";
export default {
  data() {
    return {
      openId: this.$route.query.openId ? this.$route.query.openId : "",
      infoData: "",
      show: false,
    };
  },
  created() {
    this.getInfo();
  },
  mounted() {},
  methods: {
    getInfo() {
      getInfo({ openId: this.openId })
        .then((res) => {
          console.log(res);
          this.infoData = res;
        })
        .catch((err) => {
          Toast({
            message: err.message,
            icon: "cross",
          });
        });
    },
    submit() {
      this.show = true;
    },
    close() {
      this.show = false;
    },
    unbind() {
      Unbinding({ openId: this.openId })
        .then((res) => {
          this.$router.replace({
            path: "/success",
          });
        })
        .catch((err) => {
          Toast({
            message: err.message,
            icon: "cross",
          });
        });
    },
  },
};
</script>

<style scoped lang="less">
.box {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #f5f5f5;
}
.header-tip {
  height: 80px;
  color: #ef3535;
  font-size: 28px;
  line-height: 80px;
  padding-left: 20px;
  width: 100%;
  background: #ffffff;
  box-sizing: border-box;
}
.img-box {
  width: 100%;
  height: 322px;
  img {
    width: 100%;
    height: 100%;
  }
}
.content {
  position: absolute;
  width: 680px;
  height: 282px;
  top: 149px;
  left: 35px;
  border-radius: 20px;
  background: #fff;
  // margin: 40px 35px 0 35px;
  padding: 0 20px;
  box-sizing: border-box;
}
.item-box {
  padding-top: 40px;
  display: flex;
  height: 40px;
  line-height: 40px;
  font-size: 28px;
}
.name {
  color: #666666;
  width: 150px;
}
.submit-btn {
  width: 620px;
  height: 90px;
  line-height: 90px;
  text-align: center;
  background: #fff;
  border-radius: 45px;
  color: #ef3535;
  font-size: 32px;
  font-weight: 400;
  margin: 277px 65px 0 65px;
}
.dialog {
  width: 580px;
  height: 340px;
  background: #ffffff;
  border-radius: 16px;
  .tip {
    margin-top: 20px;
    text-align: center;
    font-size: 34px;
    color: #333333;
    font-weight: 600;
  }
  .messageBox {
    margin: 30px 50px 53px 50px;
    font-size: 30px;
    color: #666666;
    text-align: center;
    height: 104px;
    .message {
      line-height: 52px;
    }
  }
  .btn-box {
    display: flex;
    width: 100%;
    height: 94px;
    line-height: 94px;
    color: #333333;
    font-size: 30px;
    justify-content: space-around;
    .btn {
      width: 50%;
      text-align: center;
    }
  }
}
</style>
